import Vue from "vue";
import VueApollo from "vue-apollo";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import store from "@/store";
import NotificationService from "@/core/services/notification.service";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import dayjs from "dayjs";

const ApolloMiddleware = {
  appsyncProvider: {},
  init() {
    const salaAppSyncConfig = {
      url: process.env.VUE_APP_SALA_API_URL,
      region: process.env.VUE_APP_COGNITO_REGION,
      auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        jwtToken: () => {
          return store.getters.accessToken;
        }
      },
      disableOffline: true
    };

    const connectAppSyncConfig = {
      url: process.env.VUE_APP_CONNECT_API_URL,
      region: process.env.VUE_APP_COGNITO_REGION,
      auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        jwtToken: () => {
          return store.getters.accessToken;
        }
      },
      disableOffline: true
    };

    const errorLink = onError(async ({ graphQLErrors, operation }) => {
      if (!store.getters.isAuthenticated) await store.dispatch("signOut");

      let vuejs = operation.extensions.vuejs;
      if (graphQLErrors && !(vuejs && vuejs.errorIgnore))
        graphQLErrors.forEach(({ message }) =>
          NotificationService.error(message)
        );
    });

    const authLink = setContext(async (_, { headers }) => {
      let validSeconds = store.getters.accessTokenPayload?.exp - dayjs().unix();
      if (validSeconds <= 300) await store.dispatch("refreshSession");
      let accessToken = store.getters.accessToken;

      if (!accessToken) {
        await store.dispatch("signOut");
      } else {
        return {
          headers: {
            ...headers,
            authorization: accessToken
          }
        };
      }
    });

    const extensionsLink = new ApolloLink((operation, forward) => {
      operation.extensions.vuejs = operation.variables["vuejs"];
      delete operation.variables["vuejs"];
      return forward(operation);
    });

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore"
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      }
    };

    const salaLink = ApolloLink.from([
      errorLink,
      extensionsLink,
      authLink,
      createSubscriptionHandshakeLink(salaAppSyncConfig)
    ]);

    const connectLink = ApolloLink.from([
      errorLink,
      extensionsLink,
      authLink,
      createSubscriptionHandshakeLink(connectAppSyncConfig)
    ]);

    const salaClient = new ApolloClient({
      link: salaLink,
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions: defaultOptions
    });

    const connectClient = new ApolloClient({
      link: connectLink,
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions: defaultOptions
    });

    this.appsyncProvider = new VueApollo({
      clients: {
        salaClient,
        connectClient
      },
      defaultClient: salaClient,
      defaultOptions: {
        $loadingKey: "loading",
        // apollo options applied to all queries in components
        $query: {
          loadingKey: "loading",
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "no-cache"
        }
      },
      errorHandler() {}
    });

    Vue.use(VueApollo);
  }
};

export default ApolloMiddleware;
