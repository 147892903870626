import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filters from "./core/filters";
import VueJWT from "vuejs-jwt";
import "./core/linq";
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

// Middleware
import AmplifyMiddleware from "./core/middleware/amplify";
import ApolloMiddleware from "./core/middleware/apollo";
// Vue 3rd party plugins
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/inline-svg";
import "./core/plugins/metronic";
import VueProgressBar from "@/view/content/progress_bar/index";

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import rbac from "./core/plugins/rbac";

Vue.config.productionTip = false;
window.PerfectScrollbar = PerfectScrollbar;

AmplifyMiddleware.init();
ApolloMiddleware.init();

Sentry.init({
  Vue,
  dsn:
    "https://33a12c9c5b554ac3c8029b933be580d3@o206765.ingest.sentry.io/4505719005839360",
  enabled: process.env.VUE_APP_NODE_ENV !== "dev",
  environment: process.env.VUE_APP_NODE_ENV,
  integrations: [new CaptureConsole({ levels: ["error"] })],
  tracesSampleRate: 1.0,
  tunnel: process.env.VUE_APP_SENTRY_TUNNEL
});

Vue.use(filters);
Vue.use(VueJWT);
Vue.use(VueProgressBar, {
  color: "#a100ff",
  thickness: "3px",
  position: "fixed"
});

Vue.use(rbac);

const app = new Vue({
  router,
  store,
  vuetify,
  apolloProvider: ApolloMiddleware.appsyncProvider,
  render: h => h(App)
}).$mount("#app");

window.app = app;
export default { app };
