import { AUTH_MODULE } from "@/core/config/role.config";

const beroutes = {
  name: "BE",
  path: "be",
  component: () => import("./BE.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu BE",
      component: () =>
        import("@/view/pages/be/navigationmenu/NavigationMenu.vue"),
      meta: {
        requiresAuth: true,
        roles: [
          ...AUTH_MODULE.BE_CRITICALS,
          ...AUTH_MODULE.BE_AVAILABILITY_REPORTS
        ]
      }
    },
    // DASHBOARDS
    // TODO - Remove after go-live of Moogsoft
    // {
    //   path: "dashboards/criticals",
    //   name: "Criticals",
    //   component: () =>
    //     import("@/view/pages/be/dashboards/criticals/Criticals.vue"),
    //   meta: {
    //     requiresAuth: true,
    //     roles: AUTH_MODULE.BE_CRITICALS
    //   }
    // },
    // REPORTING
    {
      path: "reporting/availability-report",
      name: "Availability Report",
      component: () =>
        import(
          "@/view/pages/be/reporting/availability_report/AvailabilityReport.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.BE_AVAILABILITY_REPORTS
      }
    }
  ]
};

// const criticalsscreenroutes = {
//   name: "BE Dashboards Criticals",
//   path: "/be/dashboards/screen/criticals",
//   component: () => import("@/view/pages/be/dashboards/criticals/Criticals.vue")
// };

export { beroutes };
